import { TableRow } from "@material-ui/core";
import styled from "styled-components";
import { vFlex } from "../../../../../styles/style-helpers";

export const SubscriptionsEl = styled.div`
  ${vFlex}
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  padding: 1em 2em;
  .filter-container{
      display:flex;
      justify-content: space-between;
      width:100%;

      .month-filter{
        display:flex;
        margin:10px 0px;
        gap:10px;
      }
  }
  .MuiTableContainer-root {
    height: 100%;
    width: 100%;
    margin-top: 1em;

    .secondary-table {
      > h6 {
        margin: 0.5em 0;
      }
      > table {
        margin-bottom: 0.5em;
      }
    }
  }
`;

export const RootRow = styled(TableRow)`
  & > * {
    border-bottom: unset !important;
  }
`;
