import React, { useState } from "react";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { H6 } from "../../../../../../../styles/typography";
import {
	Collapse,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
} from "@material-ui/core";
import { RootRow } from "../../../elements/subscriptions";

function CollapsableRow({ data }) {
	const [open, setOpen] = useState(false);

	return (
		<>
			<RootRow>
				{data.total_bills < 1 ? (
					<TableCell />
				) : (
					<TableCell>
						<IconButton
							aria-label="expand row"
							size="small"
							onClick={() => setOpen(!open)}
						>
							{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
						</IconButton>
					</TableCell>
				)}
				<TableCell component="th" scope="row">
					{data.name}
				</TableCell>
				<TableCell>{data.email}</TableCell>
				<TableCell>{data.plan}</TableCell>
				<TableCell>{data.frequency}</TableCell>
				<TableCell>{data.purchaseDate}</TableCell>
				<TableCell>{data.endDate}</TableCell>
				<TableCell>{data.amount}</TableCell>
				<TableCell>{data.mode}</TableCell>
				<TableCell>{data.id}</TableCell>
			</RootRow>
			{data.total_bills > 0 && (
				<TableRow>
					<TableCell style={{ paddingTop: 0, paddingBottom: 0 }} colSpan={7}>
						<Collapse in={open} timeout={"auto"} unmountOnExit>
							<div className="secondary-table">
								<H6 bold>Bills</H6>
								<Table size="small">
									<TableHead>
										<TableRow>
											<TableCell>Id</TableCell>
											<TableCell>Mode</TableCell>
											<TableCell>Frequency</TableCell>
											<TableCell>Updated At</TableCell>
											<TableCell>Amount</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{data.bills.map((bill) => (
											<TableRow>
												<TableCell>{bill.id}</TableCell>
												<TableCell>{bill.mode}</TableCell>
												<TableCell>{bill.frequency}</TableCell>
												<TableCell>
													{new Date(bill.updatedAt).toDateString()}
												</TableCell>
												<TableCell>{bill.amount}</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</div>
						</Collapse>
					</TableCell>
				</TableRow>
			)}
		</>
	);
}

export default CollapsableRow;
