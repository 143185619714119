import React, { useState } from "react";

const TableComponent = ({ bills }) => {
	const [searchTerm, setSearchTerm] = useState("");
	const [filterType, setFilterType] = useState("");
	const [paymentType, setPaymentType] = useState("");

	// Filtered data based on search and filters
	const filteredData = bills.filter((bill) => {
		const matchesSearch =
			bill?.name?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
			bill?.description?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
			bill?.id?.toLowerCase()?.includes(searchTerm?.toLowerCase());
		const matchesFilter =
			filterType === "sub"
				? bill?.isSubscriptionBill
				: filterType === "course"
				? bill?.isCourseBill
				: filterType === "bc"
				? bill?.isBroadcastClassBill
				: true;
		const matchPaymentFilter =
			paymentType === "paid"
				? bill?.status === "PAID"
				: paymentType === "not_paid"
				? bill?.status === "NOT_PAID"
				: true;
		return matchesSearch && matchesFilter && matchPaymentFilter;
	});

	return (
		<div>
			{/* Search Bar */}
			<div style={{ marginBottom: "1rem" }}>
				<input
					type="text"
					placeholder="Search by name or description"
					value={searchTerm}
					onChange={(e) => setSearchTerm(e.target.value)}
					style={{
						width: "100%",
						padding: "0.5rem",
						marginBottom: "0.5rem",
						fontSize: "1rem",
					}}
				/>
				{/* Filter Dropdown */}
				<select
					value={filterType}
					onChange={(e) => setFilterType(e.target.value)}
					style={{
						width: "100%",
						padding: "0.5rem",
						fontSize: "1rem",
					}}
				>
					<option value="">All Types</option>
					<option value="sub">Subscription Bill</option>
					<option value="course">Course Bill</option>
					<option value="bc">Broadcast Bill</option>
				</select>
				<select
					value={filterType}
					onChange={(e) => setPaymentType(e.target.value)}
					style={{
						width: "100%",
						padding: "0.5rem",
						fontSize: "1rem",
					}}
				>
					<option value="">All Payment</option>
					<option value="paid">PAID</option>
					<option value="not_paid">NOT PAID</option>
				</select>
			</div>

			{/* Table */}
			<table
				style={{
					width: "100%",
					borderCollapse: "collapse",
					textAlign: "left",
					marginBottom: "1rem",
				}}
			>
				<thead>
					<tr
						style={{
							backgroundColor: "#f5f5f5",
							borderBottom: "2px solid #ccc",
						}}
					>
						<th style={{ padding: "0.5rem", borderBottom: "1px solid #ddd" }}>
							Id
						</th>
						<th style={{ padding: "0.5rem", borderBottom: "1px solid #ddd" }}>
							Name
						</th>
						<th style={{ padding: "0.5rem", borderBottom: "1px solid #ddd" }}>
							Description
						</th>
						<th style={{ padding: "0.5rem", borderBottom: "1px solid #ddd" }}>
							Amount
						</th>
						<th style={{ padding: "0.5rem", borderBottom: "1px solid #ddd" }}>
							Status
						</th>
						<th style={{ padding: "0.5rem", borderBottom: "1px solid #ddd" }}>
							Type
						</th>
						<th style={{ padding: "0.5rem", borderBottom: "1px solid #ddd" }}>
							Payment Gateway
						</th>
					</tr>
				</thead>
				<tbody>
					{filteredData.length > 0 ? (
						filteredData.map((bill) => (
							<tr key={bill.id}>
								<td
									style={{ padding: "0.5rem", borderBottom: "1px solid #ddd" }}
								>
									{bill.id}
								</td>
								<td
									style={{ padding: "0.5rem", borderBottom: "1px solid #ddd" }}
								>
									{bill.name}
								</td>
								<td
									style={{ padding: "0.5rem", borderBottom: "1px solid #ddd" }}
								>
									{bill?.isSubscriptionBill
										? bill?.description
										: bill?.isCourseBill
										? bill?.type === "recorded"
											? bill?.regType || "STANDARD"
											: ""
										: ""}
								</td>
								<td
									style={{ padding: "0.5rem", borderBottom: "1px solid #ddd" }}
								>
									{bill?.finalAmount || bill.amount}
								</td>
								<td
									style={{ padding: "0.5rem", borderBottom: "1px solid #ddd" }}
								>
									{bill.status}
								</td>
								<td
									style={{ padding: "0.5rem", borderBottom: "1px solid #ddd" }}
								>
									{bill.type}
								</td>
								<td
									style={{ padding: "0.5rem", borderBottom: "1px solid #ddd" }}
								>
									{bill.paymentGateway || ""}
								</td>
							</tr>
						))
					) : (
						<tr>
							<td
								colSpan={5}
								style={{
									padding: "1rem",
									textAlign: "center",
									borderBottom: "1px solid #ddd",
								}}
							>
								No results found.
							</td>
						</tr>
					)}
				</tbody>
			</table>
		</div>
	);
};

export default TableComponent;
