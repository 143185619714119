import React, { useState } from "react";
import { createFilter } from "react-search-input";
import {
	PaymentBasicInfoEl,
	PaymentInfoModalEl,
} from "../broadcastClass/elements";
import { classPaymentData } from "../../../../helpers/tableData";
import Button from "../../../../components/button";
import Modal from "../../../../components/modal";
import Table from "../../../../components/table";
import Search from "../../../../components/search";
import { getYearAndMonthOptions } from "./helpers";
import { curry, merge } from "ramda";

const headers = [
	{ id: "id", label: "ID", width: 80 },
	{ id: "user", label: "User" },
	{ id: "userId", label: "User-ID", width: 80 },
	{ id: "email", label: "Email" },
	{ id: "createdAt", label: "Created At" },
	{ id: "updatedAt", label: "Updated At" },
	{ id: "amount", label: "Amount" },
	{ id: "gateway", label: "Gateway" },
	{ id: "status", label: "Status" },
	{ id: "action", label: "Actions", sortable: false },
];

function PaymentModal({
	info,
	getPaymentInfo,
	downloadPaymentInfo,
	markBillPaid,
	highlightUserMessages,
	deregister,
}) {
	const KEYS_TO_FILTER = ["id", "user", "email", "gateway", "status", "userId"];
	const [query, setQuery] = useState("");
	const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
	const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());

	const bills = info.bills.map((bill) =>
		merge(bill, {
			action: {
				status: bill.status,
				id: bill.id,
				registrationId: bill?.registrationId,
				highlightMessages: bill?.highlightMessages,
				isSeriesBill: bill?.isSeriesBill,
			},
		})
	);
	const { years, months } = getYearAndMonthOptions();

	let paymentInfo = bills
		.filter(createFilter(query, KEYS_TO_FILTER))
		.filter(({ updatedAt }) => {
			const date = new Date(updatedAt);
			return (
				(selectedYear === -1 || date.getFullYear() === selectedYear) &&
				(selectedMonth === -1 || date.getMonth() === selectedMonth)
			);
		});
	const currentEarnings = paymentInfo.reduce((acc, payment) => {
		console.log("payment", payment);
		if (payment.status === "PAID") {
			const { price, currency } = payment;
			acc[currency] = (acc[currency] || 0) + price;
			return acc;
		} else {
			return acc;
		}
	}, {});
	console.log("currentEarnings", currentEarnings);
	const paidCount = paymentInfo.filter(
		({ status }) => status === "PAID"
	).length;
	const unPaidCount = paymentInfo.length - paidCount;
	const cellModifier = curry(classPaymentData);
	const jsonToCsv = (json) => {
		delete json["amount"];
		delete json["pricing"];
		const headers = Object.keys(json[0]);
		const csvRows = [headers.join(",")];

		json.forEach((row) => {
			const values = headers.map((header) => `"${row[header] || ""}"`);
			csvRows.push(values.join(","));
		});

		return csvRows.join("\n");
	};

	// Trigger the CSV download
	const downloadCSV = () => {
		if (!paymentInfo || paymentInfo.length === 0) {
			alert("No data to download!");
			return;
		}
		const csvData = jsonToCsv(paymentInfo);
		const blob = new Blob([csvData], { type: "text/csv" });
		const url = URL.createObjectURL(blob);

		const a = document.createElement("a");
		a.href = url;
		a.download = `recordings.csv`;
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
	};

	return (
		<Modal
			onOpen={async () => await getPaymentInfo()}
			title="Payment Info"
			content={() => (
				<PaymentInfoModalEl>
					<div style={{ display: "flex" }}>
						{/* <Button onClick={async () => await downloadPaymentInfo()}>
							Download Full Payments CSV
						</Button> */}
						{/* <Button onClick={async () => downloadCSV()}>Download CSV</Button> */}
					</div>
					<PaymentBasicInfoEl>
						<div>
							Total Earnings:{" "}
							{info.earnings.map((val, i) => `${i !== 0 ? "," : ""} ${val}`)}
						</div>
						{/* <div>Paid: {info.paid}</div>
						<div>Not Paid: {info.not_paid}</div> */}
					</PaymentBasicInfoEl>
					<div>
						<div>
							Selected Earnings:{" "}
							{Object.entries(currentEarnings)
								.map(([curr, amount]) => `${curr} ${amount}`)
								.join(", ")}
						</div>
						<div>Paid: {paidCount}</div>
						<div>Not Paid: {unPaidCount}</div>
					</div>
					<div className="filter-container">
						<Search placeholder="Search" onChange={setQuery} />
						<div className="month-filter">
							<select
								value={selectedYear}
								onChange={(e) => setSelectedYear(Number(e.target.value))}
							>
								{years.map((year) => (
									<option key={year} value={year}>
										{year}
									</option>
								))}
								<option value={-1}>All</option>
							</select>
							<select
								value={selectedMonth}
								onChange={(e) => setSelectedMonth(Number(e.target.value))}
							>
								{months.map((month, index) => (
									<option key={index} value={index}>
										{month}
									</option>
								))}
								<option value={-1}>All</option>
							</select>
							<Button onClick={downloadCSV}>Download Csv</Button>
						</div>
					</div>
					<div>All Bills</div>
					<Table
						data={paymentInfo}
						headers={headers}
						rowCellModifier={cellModifier(
							markBillPaid,
							highlightUserMessages,
							deregister
						)}
						stickyHeader
					/>
				</PaymentInfoModalEl>
			)}
		/>
	);
}

export default PaymentModal;
