import React, { useEffect, useState } from "react";
import { PaymentInfoEl } from "../elements";
import CourseStore from "../store/courseStore";
import Loading from "../../../../../components/loading";
import Search from "../../../../../components/search";
import { createFilter } from "react-search-input/lib/util";
import {
	getPaymentHeaders,
	getPaymentSelectorOptions,
	KEYS_TO_FILTER_PAYMENT,
} from "../config";
import Input from "../../../../../components/input";
import Table from "../../../../../components/table";
import { getYearAndMonthOptions, paymentCellModifier } from "../helpers";
import { Text } from "../../../../../styles/typography";
import { concat, includes, pluck } from "ramda";
import Button from "../../../../../components/button";

function PaymentInfo() {
	const [query, setQuery] = useState("");
	const [billType, setBillType] = useState("all");

	const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
	const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
	const { years, months } = getYearAndMonthOptions();
	const {
		getPaymentInfo,
		paymentInfo,
		setPaymentInfo,
		curRequest: { type: courseType },
		downloadPaymentInfo,
	} = CourseStore.useContainer();

	useEffect(() => {
		(async () => {
			await getPaymentInfo();
		})();
		return () => setPaymentInfo(null);
	}, [setPaymentInfo, getPaymentInfo]);

	const bills =
		paymentInfo?.bills
			?.filter(({ status }) => billType === "all" || status === billType)
			.filter(({ createdAt }) => {
				const date = new Date(createdAt);
				return (
					(selectedYear === -1 || date.getFullYear() === selectedYear) &&
					(selectedMonth === -1 || date.getMonth() === selectedMonth)
				);
			})
			.filter(createFilter(query, KEYS_TO_FILTER_PAYMENT)) || [];

	const regs =
		paymentInfo?.registrations
			?.filter(({ status }) => billType === "all" || status === billType)
			.filter(({ userId }) => !includes(userId, pluck("userId", bills)))
			.filter(({ createdAt }) => {
				const date = new Date(createdAt);
				return (
					(selectedYear === -1 || date.getFullYear() === selectedYear) &&
					(selectedMonth === -1 || date.getMonth() === selectedMonth)
				);
			})
			.filter(createFilter(query, KEYS_TO_FILTER_PAYMENT)) || [];

	const earnings = paymentInfo?.earnings || {};
	const currentEarnings = bills.reduce((acc, payment) => {
		if (payment.status === "PAID") {
			const { amount, currency } = payment;
			acc[currency] = (acc[currency] || 0) + amount;
			return acc;
		} else {
			return acc;
		}
	}, {});
	const selectorOptions = getPaymentSelectorOptions(
		paymentInfo?.bills?.length,
		paymentInfo?.registrations?.length
	);

	const paidCount = bills.filter(({ status }) => status === "PAID").length;
	const unPaidCount = bills.length - paidCount;
	const jsonToCsv = (json) => {
		delete json["amount"];
		delete json["pricing"];
		const headers = Object.keys(json[0]);
		const csvRows = [headers.join(",")];

		json.forEach((row) => {
			const values = headers.map((header) => `"${row[header] || ""}"`);
			csvRows.push(values.join(","));
		});

		return csvRows.join("\n");
	};

	// Trigger the CSV download
	const downloadCSV = () => {
		if ((!bills || bills.length === 0) && (!regs || regs.length === 0)) {
			alert("No data to download!");
			return;
		}
		if (bills.length !== 0) {
			const csvData = jsonToCsv(bills);
			const blob = new Blob([csvData], { type: "text/csv" });
			const url = URL.createObjectURL(blob);

			const a = document.createElement("a");
			a.href = url;
			a.download = `recordings.csv`;
			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a);
		} else {
			const csvData = jsonToCsv(regs);
			const blob = new Blob([csvData], { type: "text/csv" });
			const url = URL.createObjectURL(blob);

			const a = document.createElement("a");
			a.href = url;
			a.download = `recordings.csv`;
			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a);
		}
	};
	return (
		<PaymentInfoEl>
			<Loading loading={!paymentInfo}>
				<div style={{ width: "100%" }}>
					{/* <Button onClick={async () => await downloadPaymentInfo()}>
						Download Full Payments CSV
					</Button> */}
				</div>
				<div className="filter-container">
					<Search placeholder={"Search"} onChange={setQuery} />
					<div className="month-filter">
						<select
							value={selectedYear}
							onChange={(e) => setSelectedYear(Number(e.target.value))}
						>
							{years.map((year) => (
								<option key={year} value={year}>
									{year}
								</option>
							))}
							<option value={-1}>All </option>
						</select>
						<select
							value={selectedMonth}
							onChange={(e) => setSelectedMonth(Number(e.target.value))}
						>
							{months.map((month, index) => (
								<option key={index} value={index}>
									{month}
								</option>
							))}
							<option value={-1}>All </option>
						</select>
						<Button onClick={async () => downloadCSV()}>Download CSV</Button>
					</div>
				</div>
				<div className="basic-info">Paid: {paidCount}</div>
				<div className="basic-info">Not Paid: {unPaidCount}</div>
				<div className="basic-info">
					<div>
						<Text>
							<b>Total Earnings:</b>{" "}
							{Object.entries(earnings)
								.map(([curr, amount]) => `${curr} ${amount}`)
								.join(", ")}
						</Text>
						<Text>
							<b>Selected Earnings:</b>{" "}
							{Object.entries(currentEarnings)
								.map(([curr, amount]) => `${curr} ${amount}`)
								.join(", ")}
						</Text>
					</div>
					{courseType !== "recorded" && (
						<div className="selector">
							<Input
								type="list"
								label=""
								items={selectorOptions}
								value={billType}
								onChange={(e) => setBillType(e.target.value)}
							/>
						</div>
					)}
				</div>
				<Table
					data={bills.concat(regs)}
					headers={getPaymentHeaders(bills.length, regs.length)}
					rowCellModifier={paymentCellModifier}
					stickyHeader
				/>
			</Loading>
		</PaymentInfoEl>
	);
}

export default PaymentInfo;
